import React from "react";
import classes from "./call_to_action.module.css";
import ImageDoorShape from "../UI/ImageDoorShape";
import Button from "../UI/Button";
import { NavLink } from 'react-router-dom';
const BussSuppActionBtn = () => {
 

    const btnStyles = {
        display: window.innerWidth <= 1024 ? 'inline-block' : 'inline-block', 
        margin: window.innerWidth <= 1024 ? '0' : '0',
        marginBottom: window.innerWidth <= 1024 ? '0' : '0',
        marginTop: window.innerWidth >= 1024 ? '2em' : '0',
        marginRight: window.innerWidth <= 1024 ? '1.5em' : '0',
        border:  window.innerWidth <= 1024 && 'solid 2px #EB2590',
        backgroundColor: '#FDECF5',
        color: '#EB2590',
        width: "10.375em",
        height: "3.2em",
        borderRadius: window.innerWidth <= 1024 && "9px",
        fontSize: "14px",
        padding: "7px 10px"
    };


    return (
        <div className={classes['banner__container']}>
             <div className={classes['action_tab']}>
             <h1 className={classes['word_two']}>Have a Program for 
              <br></br>Women Entrepreneurs?</h1>
              <Button text={"Contact us today"} to = {"/support"} styles = {btnStyles} />
             </div>
        </div>
    )
}

export default BussSuppActionBtn;